import { render, staticRenderFns } from "./barging-nomination-form.vue?vue&type=template&id=16ca70f0&scoped=true&"
import script from "./barging-nomination-form.vue?vue&type=script&lang=js&"
export * from "./barging-nomination-form.vue?vue&type=script&lang=js&"
import style0 from "./barging-nomination-form.vue?vue&type=style&index=0&id=16ca70f0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ca70f0",
  null
  
)

export default component.exports